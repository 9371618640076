<template>
  <ActionPopUp v-if="authenticated" />
  <ThankYouPopUp :is-active="showThankYouMessage" @close="showThankYouMessage = false" />
  <div class="wrapper">
    <MainBar v-if="authenticated" />
    <SmallNotification />
    <div class="wrapper__content">
      <Header v-if="authenticated" />

      <Navigator v-if="isMobile" />

      <main class="main" :class="{ main_opacity: !authenticated }">
        <router-view :key="refreshKey"></router-view>
      </main>
    </div>
  </div>
  <Loader :is-loading="loading" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import MainBar from '@/components/SideBar/MainBar.vue';
import Header from '@/components/Header/Header.vue';
import ThankYouPopUp from '@/layouts/ThankYouPopUp.vue';
import ActionPopUp from '@/components/Popups/Switcher.vue';
import SmallNotification from '@/components/UI/SmallNotification.vue';
import useLoadingStore from '@/store/loading';
import useLocaleStore from '@/store/locale';
import useAuthStore from '@/store/auth';
import Loader from '@/components/UI/Loader.vue';
import Navigator from '@/components/Header/Navigator.vue';
import { useUIStore } from '@/store/ui';

const localeStore = useLocaleStore();
const uiStore = useUIStore();

const refreshKey = ref<number>(0);
const showThankYouMessage = ref<boolean>(false);

const isMobile = computed(() => uiStore.isMobile);
const authenticated = computed<boolean>(() => useAuthStore().isLoggedIn);
const loading = computed<boolean>(() => useLoadingStore().isLoading);

watch(
  () => localeStore.currentLang,
  () => {
    refreshKey.value += 1;
    console.log('refreshKey', refreshKey.value);
  }
);
</script>

<style lang="scss">
@import '@/assets/styles/main-page.scss';
@import '@/assets/styles/utils/icons';
@import '@/assets/styles/utils/toast';
@import '@/assets/styles/tooltip.scss';
@import '@/assets/styles/popup-actions.scss';
@import '@/assets/styles/popup.scss';
@import '@/assets/styles/player.scss';
@import '@/assets/styles/table-site.scss';
@import '@/assets/styles/profile-card.scss';
@import '@/assets/styles/color-edit.scss';
@import '@/assets/styles/credit-history.scss';
@import '@/assets/styles/file-upload.scss';
@import '@/assets/styles/meeting.scss';
@import '@/assets/styles/create-campaign.scss';
//@import '@/assets/styles/responsive/mob_sidebar';
//@import '@/assets/styles/responsive/mob_player.scss';
//@import '@/assets/styles/responsive/mob_history-modal';

@import '@/assets/styles/form-site.scss';
@import '@/assets/styles/settings.scss';
@import '@/assets/styles/modal-main.scss';
@import '@/assets/styles/add-block.scss';
@import '@/assets/styles/modal.scss';
@import '@/assets/styles/responsive/mob_sidebar.scss';
</style>
