import {
  Filter,
  IBusiness,
  IUser,
  USER_ROLES,
  UserBusiness,
  UserRoleLevels
} from '@/types/interfaces';
import { defineStore } from 'pinia';
import { useCampaignStore } from '@/store/campaign';
import { useLeadStore } from '@/store/lead';
import { resetAllStores } from '@/store/reset';
import {
  ApiBusinessUserResponse,
  ApiLoginErrorResponse,
  ApiLoginResponse,
  ApiLoginSuccessResponse
} from '@/types/api_interface';
import { refreshFn } from '@/common/lib/refresh';
import router from '@/router/router';
import { useNotificationStore } from '@/store/notification';
import { useUIStore } from '@/store/ui';
import { get, post } from '@/common/lib/api';
import useLocaleStore from '@/store/locale';
import { API_URL } from '@/common/lib/url';
import { showToast } from '@/common/lib/toast';

interface AuthState {
  businesses: IBusiness[];
  currentBusiness: IBusiness | null;
  currentUser: IUser | null;
  isLoggedIn: boolean;
  isOwner: boolean;
  imageUpdateKey: number;
}

export const useAuthStore = defineStore({
  id: 'auth-store',
  state: (): AuthState => ({
    businesses: [] as IBusiness[],
    currentBusiness: null as IBusiness | null,
    currentUser: {} as IUser,
    isLoggedIn: false,
    isOwner: false,
    imageUpdateKey: Date.now()
  }),
  getters: {
    getCurrentUser: state => (state.isLoggedIn ? state.currentUser : null),
    getIsLoggedIn: state => state.isLoggedIn,
    getBusinesses: state => state.businesses,
    getCurrentBusiness: state => state.currentBusiness,
    getIsOwner: state => state.isOwner
  },
  actions: {
    async login(user: Partial<IUser>): Promise<ApiLoginResponse | null> {
      const [status, response] = await post<ApiLoginResponse>('auth/login', user);
      if (status === 200 && response.success) {
        localStorage.setItem('token', (response as ApiLoginSuccessResponse).accessToken);
        await this.fetchCurrentUser();
        return response;
      } else {
        showToast((response as ApiLoginErrorResponse).message, 'error');
        console.error('An unexpected error occurred');
        return null;
      }
    },

    async register(user: Partial<IUser>): Promise<{ success: boolean }> {
      const [status, response] = await post<ApiLoginSuccessResponse | ApiLoginErrorResponse>(
        `${API_URL}register`,
        user
      );

      if (status === 200 && (response as ApiLoginSuccessResponse).accessToken) {
        localStorage.setItem('token', (response as ApiLoginSuccessResponse).accessToken);
        this.setCurrentUser((response as ApiLoginSuccessResponse).user);
        showToast('Registration successful.', 'success');
        return { success: true };
      }

      const errorMessage =
        status === 401
          ? (response as ApiLoginErrorResponse).message || 'Unauthorized.'
          : (response as ApiLoginErrorResponse).message || 'An unexpected error occurred.';

      showToast(errorMessage, 'error'); // Handle error toast in store
      return { success: false };
    },

    async postLoginActions(token: string) {
      localStorage.setItem('token', token);
      await this.fetchCurrentUser();
    },

    async logout(): Promise<void> {
      try {
        localStorage.removeItem('token');
        localStorage.removeItem('businessId');
        resetAllStores([
          useCampaignStore(),
          useLeadStore(),
          useNotificationStore(),
          useUIStore(),
          this
        ]);
        await router.push('/auth/login');
      } catch (error) {
        console.error('An error occurred while logging out the user:', error);
        throw new Error('Failed to log out user');
      }
    },

    async fetchCurrentUser() {
      const campaignStore = useCampaignStore();
      const localeStore = useLocaleStore();

      const [status, response] = await get<{
        success: boolean;
        user: IUser;
        businesses: UserBusiness[];
      }>(`${API_URL}getCurrentUser`);

      if (status !== 200 || !response.success) {
        console.error('Failed to fetch current user: Invalid status or unsuccessful response');
        throw new Error('Failed to fetch current user');
      }

      this.setCurrentUser(response.user);

      if (response.user.userLanguage != null) {
        localeStore.setLanguage(response.user.userLanguage);
      }

      const businessData: IBusiness[] = response.businesses.map(
        (business: UserBusiness) =>
          ({
            ...business.business,
            _id: business.businessId,
            role: business.role
          }) as IBusiness
      );

      if (businessData.length > 0) {
        this.setBusinesses(businessData);
        const url = document.location.pathname;
        const regex = /\/b\/([a-zA-Z0-9]+)/;
        const currentBusiness = url.match(regex)?.[1];

        let selectedBusiness = businessData[0];
        if (currentBusiness) {
          const business = businessData.find(business => business._id === currentBusiness);
          if (business) {
            selectedBusiness = business;
          }
        }
        await this.setCurrentBusiness(selectedBusiness);
        await campaignStore.fetchCampaigns();
      } else {
        console.log('No businesses found for the user');
      }

      this.setLoggedInState(true);
      return response;
    },

    async fetchUsersForBusiness(page = 1, limit = 10, filters: Filter[] = [], search = '') {
      const params = new URLSearchParams({
        page: String(page),
        limit: String(limit),
        filters: encodeURIComponent(JSON.stringify(filters)),
        search
      });

      const [status, response] = await get<ApiBusinessUserResponse>(
        `${API_URL}users?${params.toString()}`
      );

      if (status !== 200 || !response.success) {
        console.error('Failed to fetch users: Invalid status or unsuccessful response');
        showToast('Failed to fetch users. Please try again.', 'error');
        return null;
      }

      const { users, pagination } = response.response;
      return { users, pagination };
    },

    checkPermission(minimumRole: USER_ROLES) {
      if (this.currentBusiness) {
        const userRoleLevel = UserRoleLevels[this.currentBusiness.role];
        const minimumRoleLevel = UserRoleLevels[minimumRole];
        return userRoleLevel <= minimumRoleLevel;
      }
      return true;
    },

    reset() {
      this.$reset();
    },

    async setCurrentBusiness(business: IBusiness) {
      const url = router.currentRoute.value.path;
      const regex = /\/b\/[a-zA-Z0-9]+/;
      const newUrl = url.replace(regex, `/b/${business._id}`);
      if (url !== newUrl) {
        return await router.push(newUrl);
      }

      this.$patch({ currentBusiness: business });
      localStorage.setItem('businessId', business._id);
      sessionStorage.clear();
      (refreshFn as any)?.();
    },

    setLoggedInState(isLoggedIn: boolean) {
      this.$patch({ isLoggedIn });
    },
    setBusinesses(businesses: IBusiness[]) {
      this.$patch({ businesses });
    },
    setCurrentUser(user: IUser | null) {
      this.$patch({ currentUser: user });
    }
  },
  persist: false
});

export default useAuthStore;
