// globalErrorHandler.ts
import { App, ComponentPublicInstance } from 'vue';
import axios from 'axios';

const isProduction = import.meta.env.NODE_ENV === 'production';

export const setupGlobalErrorHandler = (app: App<Element>): void => {
  // Custom Vue warning handler to filter specific warnings
  app.config.warnHandler = (msg, instance, trace) => {
    if (msg.includes('<Suspense> is an experimental feature')) {
      // Suppress the experimental Suspense warning
      return;
    }
    console.warn(`[Vue Warn]: ${msg}${trace}`);
    if (instance) {
      console.warn('Component Name:', instance.$options?.name || 'Anonymous Component');
    }
  };
  app.config.errorHandler = (err, instance, info) => {
    handleGlobalError(err, instance, info, 'Vue Error');
  };

  window.addEventListener('unhandledrejection', event => {
    event.preventDefault();
    handleGlobalError(event.reason, null, '', 'Unhandled Promise Rejection');
  });

  window.addEventListener('error', event => {
    event.preventDefault();
    handleGlobalError(event.error, null, '', 'Global JavaScript Error');
  });

  axios.interceptors.response.use(
    response => response,
    error => {
      handleAxiosError(error);
      return Promise.reject(error);
    }
  );
};

function handleGlobalError(
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string,
  context: string
) {
  const errorMessage = getErrorMessage(err);
  console.groupCollapsed(`[${context}] ${errorMessage}`);
  console.error(`Error Info: ${info}`);
  logErrorDetails(err, instance);
  console.groupEnd();

  if (isProduction) {
    reportErrorToService(err, context, instance);
  }
}

function handleAxiosError(error: any) {
  const status = error?.response?.status || 'Unknown';
  const message = error?.response?.data?.message || 'Network or API error occurred';
  console.error(`[Axios Error] Status: ${status}, Message: ${message}`);
}

function getErrorMessage(err: unknown): string {
  if (err instanceof Error) return err.message;
  if (typeof err === 'string') return err;
  return JSON.stringify(err);
}

function logErrorDetails(err: unknown, instance: ComponentPublicInstance | null) {
  if (instance) {
    const componentName = getComponentName(instance);
    console.log(`Component: ${componentName}`);
    console.log('Props:', instance.$props);
    console.log('Data:', safeStringify(instance.$data));
    logDynamicProperties('Computed Properties', instance, instance.$options.computed);
    logDynamicProperties('Methods', instance, instance.$options.methods);
    logDynamicProperties('Watchers', instance, instance.$options.watch);
    logSlots(instance);
  }
}

function getComponentName(instance: ComponentPublicInstance): string {
  const name = instance.$options.name || instance.$options.__file || 'Anonymous Component';
  return name.includes('Anonymous')
    ? `Anonymous (File: ${instance.$options.__file || 'unknown'})`
    : name;
}

function logDynamicProperties(
  label: string,
  instance: ComponentPublicInstance,
  properties: Record<string, any> | undefined
) {
  if (!properties) return;
  console.log(`${label}:`);
  Object.entries(properties).forEach(([key, value]) => {
    const resolvedValue = typeof value === 'function' ? value.bind(instance) : value;
    console.log(`${key}:`, resolvedValue);
  });
}

function logSlots(instance: ComponentPublicInstance) {
  if (instance.$slots) {
    console.log('Slots:', Object.keys(instance.$slots));
  }
}

function safeStringify(data: unknown): string {
  try {
    return JSON.stringify(data, null, 2);
  } catch (e) {
    return '[Circular Data]';
  }
}

function reportErrorToService(
  err: unknown,
  context: string,
  instance: ComponentPublicInstance | null
) {
  console.log(`[Error Reporting] Sending error to monitoring service...`);
  // Integrate with any error tracking service (e.g., Sentry)
  // Example: Sentry.captureException(err, { extra: { context, instance } });
}
