import { defineStore } from 'pinia';
import { CampaignBusiness, Filter, ICampaign, SelectItem } from '@/types/interfaces';
import axios from '@/common/lib/axios';
import router from '@/router/router';
import { FetchCampaignUsersResponse } from '@/types/api_interface';
import { Campaign_URL } from '@/common/lib/url';

interface CampaignState {
  campaigns: ICampaign[];
  currentCampaign: ICampaign | null;
  selectedCampaignList: SelectItem[];
  actionCampaignList: ICampaign[];
  actionCampaign: ICampaign | null;
  campaignUsers: number;
  campaignBusinesses: number;
  businesses: CampaignBusiness[] | [];
  hasCreatedCampaign: boolean;
  showVirtualNumberModal: boolean;
  virtualNumberCreated: boolean;
}

export const useCampaignStore = defineStore({
  id: 'campaign-store',
  state: (): CampaignState => ({
    campaigns: [] as ICampaign[],
    currentCampaign: null as ICampaign | null,
    selectedCampaignList: [] as SelectItem[],
    actionCampaignList: [] as ICampaign[],
    actionCampaign: null as ICampaign | null,
    campaignUsers: 0,
    campaignBusinesses: 0,
    businesses: [] as CampaignBusiness[],
    hasCreatedCampaign: false,
    showVirtualNumberModal: false,
    virtualNumberCreated: false
  }),
  getters: {
    getCampaigns: state => state.campaigns,
    getCurrentCampaign: state => state.currentCampaign,
    getSelectedCampaignList: state => state.selectedCampaignList,
    getCampaignUsers: state => state.campaignUsers,
    getActionCampaignList: state => state.actionCampaignList,
    getActionCampaign: state => state.actionCampaign,
    getCampaignBusinesses: state => state.campaignBusinesses
  },
  actions: {
    async fetchCampaigns(page = 1, limit = 10, filters: Filter[] = [], isSingle = false) {
      try {
        if (isSingle) {
          const single = this.campaigns.find(campaign => campaign._id === filters[0].value);
          if (single) return { campaigns: [single] };
        }
        const params = new URLSearchParams({
          page: String(page),
          limit: String(limit),
          filters: encodeURIComponent(JSON.stringify(filters))
        });
        const response = await axios.get(Campaign_URL, { params });

        if (response.data.success) {
          const campaigns = response.data.response.campaigns;
          this.populateCampaigns(campaigns);

          return { campaigns, pagination: response.data.response.pagination };
        }
      } catch (error) {
        console.error('An error occurred while fetching the campaigns:', error);
        throw new Error('Failed to fetch campaigns');
      }
    },
    populateCampaigns(campaigns: ICampaign[]) {
      this.setCampaigns(campaigns);
      this.setSelectedCampaignList(
        campaigns.map((campaign: ICampaign) => ({
          key: campaign._id,
          name: campaign.name,
          status: false
        }))
      );
    },
    async fetchCampaignUsers(campaignId: string): Promise<FetchCampaignUsersResponse['users']> {
      try {
        const { data } = await axios.get<FetchCampaignUsersResponse>(Campaign_URL + 'users', {
          params: {
            campaignId
          }
        });

        if (data.success) {
          this.campaignUsers = data.users.length;
          return data.users;
        } else {
          console.error('Failed to fetch campaign users');
          return [];
        }
      } catch (e) {
        console.error(e);
        throw new Error('Failed to fetch campaign users');
      }
    },

    // TODO: Implement this method
    // async fetchCampaignBusinesses(campaignId: string) {
    //   try {
    //     const { data } = await axios.get(Campaign_URL + 'businesses', {
    //       params: {
    //         campaignId
    //       }
    //     });
    //     if (data.success && data.businesses) {
    //       this.campaignBusinesses = data.numberOfBusinesses;
    //       this.businesses = data.businesses;
    //       return data;
    //     }
    //   } catch (e) {
    //     console.error(e);
    //     throw new Error('Failed to fetch campaign businesses');
    //   }
    // },

    reset() {
      this.$reset();
    },

    setCampaigns(campaigns: ICampaign[]) {
      this.$patch({ campaigns });
      this.$patch({ actionCampaignList: campaigns });
    },

    setCurrentCampaign(campaign: ICampaign | null) {
      if (campaign) {
        const url = router.currentRoute.value.path;
        const regex = /\/c\/[a-zA-Z0-9]+/;
        const newUrl = url.replace(regex, `/c/${campaign._id}`);
        if (url !== newUrl) {
          return router.push(newUrl);
        }
      }
      if (!campaign) {
        const storedCurrentCampaign = sessionStorage.getItem('currentCampaign');
        if (storedCurrentCampaign) {
          this.currentCampaign = JSON.parse(storedCurrentCampaign) as ICampaign;
          return;
        }
      }
      this.$patch({ currentCampaign: campaign });
      this.$patch({ actionCampaign: campaign });
      if (campaign) {
        //this.fetchCampaignUsers(campaign._id).catch(console.error);
        sessionStorage.setItem('currentCampaign', JSON.stringify(campaign));
      } else {
        this.campaignUsers = 0;
        sessionStorage.removeItem('currentCampaign');
      }
    },

    setSelectedCampaignList(newCampaigns: SelectItem[]) {
      this.selectedCampaignList = newCampaigns;
    },
    setHasCreatedCampaign(flag: boolean) {
      this.$patch({ hasCreatedCampaign: flag });
    },
    setShowVirtualNumberModal(flag: boolean) {
      this.$patch({ showVirtualNumberModal: flag });
    },
    setVirtualNumberCreated(flag: boolean) {
      this.$patch({ virtualNumberCreated: flag });
    }
  },
  persist: false
});

export default useCampaignStore;
