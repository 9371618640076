const images = import.meta.glob('@/assets/img/*', { eager: true });
const fallbackColor: string = '#F5F5F9';

export function getImageUrl(imageName: string): string {
  const matchedImage = Object.entries(images).find(([path]) => path.includes(imageName));
  return matchedImage ? (matchedImage[1] as any).default : '';
}

export function getBackgroundImageStyle(imageName: string): string {
  return `background-image: url(${getImageUrl(imageName)});`;
}

export function svgHref(svgName: string | undefined): string {
  if (svgName) {
    return new URL('@/assets/sprites/sprite.svg', import.meta.url).href + `#${svgName}`;
  }
  return '';
}

export function isExternalUrl(url: string): boolean {
  return /^https?:\/\//.test(url);
}

export function getBackgroundImageOrColor(imageName: string): Record<string, string> {
  if (isExternalUrl(imageName)) {
    return { 'background-image': `url(${imageName})` };
  } else if (imageName) {
    return { 'background-image': `url(${getImageUrl(imageName)})` };
  }
  return { 'background-color': fallbackColor };
}

export function getImageOrDefault(imageName: string): string {
  if (isExternalUrl(imageName)) {
    return imageName;
  }
  const imageUrl = getImageUrl(imageName);
  return imageUrl || getImageUrl('no-user-avatar.png');
}

export const spriteUrl = (iconName: string) => {
  return new URL(`@/assets/sprites/sprite.svg`, import.meta.url).href + `#ic-${iconName}`;
};
