import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases } from 'vuetify/iconsets/fa';
import { mdi } from 'vuetify/iconsets/mdi';

const vuetifyPlugin = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
});

export default vuetifyPlugin;
