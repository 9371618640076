import { Languages } from '@/common/language/translator';
import { Ref } from 'vue';
import { CallTableData, CdrResponse, ICall } from '@/common/lib/calls';

export interface I_BusinessBase {
  name: string;
  brandName?: string;
  taxNumber: string;
  address?: string;
  country?: string;
  contactName?: string;
  email: string;
  phone?: string;
  type?: string;
  leadStatuses?: ObjectId[];
  image?: string;
}

export interface IBusiness extends I_BusinessBase {
  _id: string;
  role: USER_ROLES;
}

export interface UserBusiness {
  businessId: ObjectId;
  role: USER_ROLES;
  userId: ObjectId;
  business: I_BusinessBase;
}

export interface TableHeaderNames {
  key: string;
  title?: string;
  isSVG?: boolean;
  svgName?: string;
}

export interface LeadTableHeaderNames {
  key: string;
  title?: string;
  isSVG?: boolean;
  svgName?: string;
  status: boolean;
}

export interface IUser {
  _id: string;
  email: string;
  phone?: string;
  name?: string;
  password: string;
  status?: string;
  role?: USER_ROLES;
  userId?: string;
  user?: {
    name: string;
    email: string;
    phone: string;
    createdAt: string;
  };
  image: string;
  userLanguage?: Languages | null;
  isAdmin?: boolean;
  createdAt?: Date;
  about?: string;
  country?: string;
}

export interface UserData {
  _id?: string;
  name: string;
  email: string;
  phone: string;
  createdAt: string;
  status: string;
  image: string;
}

export interface UserTableData {
  _id: string;
  userId: string;
  status: string;
  role: string;
  user: UserData;
}

export interface DefaultTableBodyProps {
  _id: string | number;

  [key: string]: any;
}

export type TableDataType =
  | UserTableData
  | ICampaign
  | LeadTableData
  | InvitedUserItem
  | CallTableData
  | CdrResponse
  | DefaultTableBodyProps;

export interface LeadTableData {
  _id: string;
  campaignId: string;
  campaignName: string;
  lead: {
    _id: string;
    name: string;
    email: string;
    phone: string;
    date: string;
    source: string;
    status: ILeadStatus;
    utm: string;
    rating: string;
    meetingStatus: string;
    isPhoneLead: boolean;
    meta: any;
    createdAt: string;
    updatedAt: string;
  };
}

export interface paymentDetailsType {
  _id?: string;
  userID?: string;
  cardDetails: string;
  bankDetails: {
    ownerName: string;
    bankNumber: string;
    branchNumber: string;
    accountNumber: string;
  };
}

export interface InvoiceHistory {
  _id?: string;
  userID?: string;
  invoiceNo: string;
  date: Date;
  amount: number;
  preview: string;
}

export interface ILeadStatus {
  _id: string;
  name: string;
  color: string;
  order: number;
  action: LeadActions;
  isDefault?: boolean;
  showColorPalette?: boolean;
}

export interface ICampaign {
  _id: string;
  business_id?: string;
  businessId?: string[];
  name: string;
  email?: string;
  links: string[];
  tags: string[];
  startDate: string;
  endDate: string;
  isEnabled?: boolean;
  smsNotifications?: string[];
  emailNotifications?: string[];
  virtualNumber?: string | null;
  notes?: string;
  leads: ILead[];
  totalSales: number;
  leadsCount: number;
  facebookCampaignId?: string;
  image: string;
  role?: USER_ROLES;
  userCount?: number;
}

export interface CampaignBusiness {
  _id: string;
  createdAt: string;
  email: string;
  name: string;
  phone: string;
}

export interface ICampaignModal {
  campaignId: string;
  campaign?: {
    name: string;
  };
}

export interface ILead {
  _id?: string;
  campaign_id: string;
  name: string;
  email: string;
  phone: string;
  status: LEAD_STATUS;
  source: string;
  date: Date;
  meetingStatus: MEETING_STATUS;
  utm: string;
  rating: string;
  isPhoneLead?: boolean;
  newsletters?: boolean;
  meta?: any;
}

export interface InvitedUser {
  businessId: string;
  email: string;
  createdAt: string;
  role: string;
  status: string;
  token: string;
  _id: string;
}

export interface MenuItem {
  link: string;
  icon: string;
  stroke?: string;
  strokeWidth?: string;
  icon_fill_rule?: string;
  fill?: string;
  text: string;
  name: string;
}

export interface INotification {
  _id: string;
  userId: string;
  businessId?: string | null;
  action: string;
  isRead: boolean;
  content: { [key: string]: any };
  createdAt?: string;
  updatedAt?: string;
  title?: string;
}

export type Tab = {
  displayText: string;
  value: string;
};
export type HistoryTab = {
  displayText: string;
  value: LeadHistorySection;
};
export type CodeTab = {
  displayText: string;
  value: CodeLanguage;
};
export type CallbackTab = {
  displayText: string;
  value: CallbackAction;
};

export interface IPagination {
  totalPages?: number;
  totalDocs?: number;
  currentPage?: number;
}

export interface FacebookIntegrationType {
  _id: string;
  label: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  synced: boolean;
}

export enum CodeLanguage {
  HTML = 'html',
  JS = 'javascript',
  PHP = 'php',
  JAVA = 'java'
}

export interface CreateMaskyooParams {
  campaignId?: string;
  maskyoo?: string; // required
  call_destination_phone: string; // required
  description: string; // required
}

export enum LeadHistoryType {
  CALL = 'CALL',
  ACTION = 'ACTION',
  FILE = 'FILE',
  COMMENT = 'COMMENT'
}

export enum ActionType {
  READ = 'READ',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  SELL = 'SELL',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MEETING = 'MEETING'
}

export interface IFile {
  _id?: string;
  leadId: string;
  fileName: string;
  filePath: string;
  fileType: string;
  fileSize: number;
  createdBy: string;
  createdAt: string;
  deletedAt: string | null;
  previewPath: string | null;
  expiredAt?: string | null;
}

export interface IComment {
  _id?: string;
  text: string;
  createdBy: string;
  leadId: string;
  createdAt: string;
  updatedAt?: string;
}

export interface IAction {
  actionType: ActionType;
  createdAt: string;
  userId: string;
  userName: string;
}

export interface ILeadHistory {
  _id: string;
  createdAt: string;
  updatedAt: string;
  files?: IFile[];
  comments?: IComment[];
  calls?: ICall[];
  actions?: IAction[];
}

export interface Filter {
  field: string;
  value: string | string[];
  operation: string;
}

export enum SelectRoles {
  Owner = 'Owner',
  Affiliate = 'Affiliate',
  Lead = 'Lead',
  Writer = 'Writer',
  Reader = 'Reader'
}

export type Role = {
  role: string;
  displayText: string;
  index: number;
};

export enum USER_ROLES {
  Admin = 'Admin',
  Owner = 'Owner',
  Affiliate = 'Affiliate',
  Lead = 'Lead',
  Writer = 'Writer',
  Reader = 'Reader',
  NONE = 'None'
}

export interface Integration {
  _id: string;
  label: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  userId?: string;
}

export interface GoogleIntegrations {
  calendar: Integration | null;
  mail: Integration | null;
  maps: Integration | null;
}

export interface credentialIntegrations {
  facebook: Integration | null;
  instagram: Integration | null;
  linkedin: Integration | null;
}

export interface AnalyticsIntegrations {
  google: Integration | null;
  facebook: Integration | null;
  instagram: Integration | null;
  linkedin: Integration | null;
}

export interface AdsManagerIntegrations {
  facebook: Integration | null;
  google: Integration | null;
  linkedin: Integration | null;
}

export type SlugMap = {
  [key in
    | 'google-calendar'
    | 'google-mail'
    | 'google-maps'
    | 'facebook-credentials'
    | 'facebook-page'
    | 'instagram-credentials'
    | 'linkedin-credentials'
    | 'google-analytics'
    | 'facebook-analytics'
    | 'instagram-analytics'
    | 'linkedin-analytics'
    | 'facebook-ads-manager'
    | 'google-ads-manager'
    | 'linkedin-ads-manager']: { target: Ref<any>; key: keyof any };
};

export interface InvitedUserItem {
  _id?: string;
  name?: string;
  email: string;
  isNew?: boolean;
  isInvited?: boolean;
  image?: string;
}

export interface SuggestedUserToCampaign extends InvitedUserItem {
  status: USER_STATUS;
  role?: string; // Add the role property here
}

export interface UserToBeAssigned {
  email: string;
  role: string;
}

export interface ICUser {
  _id?: string;
  email: string;
  phone: string;
  name: string;
  image?: string;
  createdAt: string;
}

export interface ICampaignUser {
  _id?: string;
  campaignId: string;
  status: string;
  role: string;
  userId: ICUser;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ApiKey {
  userId: number;
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
}

export interface DateRange {
  start: Date | null;
  end: Date | null;
}

export const UserRoleLevels: { [key in USER_ROLES]: number } = {
  [USER_ROLES.Owner]: 0,
  [USER_ROLES.Admin]: 10,
  [USER_ROLES.Affiliate]: 100,
  [USER_ROLES.Lead]: 300,
  [USER_ROLES.Writer]: 400,
  [USER_ROLES.Reader]: 500,
  [USER_ROLES.NONE]: 1000
};

export enum USER_STATUS {
  Active = 'Active',
  Pending = 'Pending',
  Verified = 'Verified'
}

export enum LeadActions {
  SendEmail = 'Send Email',
  SendSMS = 'Send SMS',
  CallBack = 'Call Back',
  None = 'None'
}

export enum LEAD_RATING {
  Rating1 = 'Rating1',
  Rating2 = 'Rating2',
  Rating3 = 'Rating3'
}

export type Column = {
  key: string;
  text: string;
  status: boolean;
};
export type SelectItem = {
  key: string;
  name: string;
  status: boolean;
};

export enum LEAD_STATUS {
  New = 'New',
  Paused = 'Paused',
  Decline = 'Decline',
  Waiting = 'Waiting'
}

export enum CALL_STATUS {
  ANSWER = 'ANSWER',
  NO_ANSWER = 'NOANSWER',
  BUSY = 'BUSY',
  CALLER_CANCEL = 'CALLER CANCEL',
  NOT_ACTIVE = 'NOT ACTIVE',
  BLACK_LIST = 'Black List',
  OUT_OF_TIME = 'OUT OF TIME'
}

export enum MEETING_STATUS {
  NONE = 'No Meeting',
  SENT = 'Invitation Sent',
  RECEIVED = 'Received',
  CONFIRMED = 'Meeting Confirmed',
  CANCELED = 'Meeting Canceled'
}

export enum LeadHistorySection {
  All = 'All',
  Files = 'Files',
  Calls = 'Calls',
  Actions = 'Actions'
}

export enum CallbackAction {
  SMS = 'SMS',
  Email = 'Email'
}
