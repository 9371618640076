<template>
  <ErrorMessage v-slot="{ message }" :name="name">
    <div v-if="message" class="auth-error">
      <div class="auth-error__icon">
        <svg class="icon ic-info" width="16" height="16">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-info"></use>
        </svg>
      </div>
      <div class="auth-error__text">{{ message }}</div>
    </div>
  </ErrorMessage>
</template>

<script setup lang="ts">
import { ErrorMessage } from 'vee-validate';

defineProps({
  name: {
    type: String,
    required: true
  },
  touched: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss">
.form-site__item {
  position: relative; // Make sure this class is applied to the container of the input and the error message
  margin-bottom: 30px; // Increase margin to make room for the error message

  .auth-error {
    position: absolute;
    bottom: -20px; // Adjust the negative offset to properly position the error message
    left: 0;
    color: $color-red;
    display: flex;
    align-items: center;
    gap: 8px;

    &__icon {
      flex-shrink: 0;

      .icon {
        display: block;
        fill: $color-red;
      }
    }

    &__text {
      @include font(none, none, 1.3rem, none, $color-red);
    }
  }
}

@media only screen and (max-width: 400px) {
  .form-site__item {
    margin-bottom: 40px;
  }

  .auth-error {
    position: relative;
    bottom: -3px;
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;
    font-size: 1.3rem;

    &__text {
      @include font(none, none, 1rem, none, $color-red);
    }
  }
}
</style>
